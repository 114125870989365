import React, { memo, useState, useContext, useEffect } from "react";

//Context
import { ContextCreate } from "../../Autenticacion/Context";

//Librerias
import { Link } from "react-router-dom";

//componentes Material-UI
import {
    Drawer,
    Box,
    Divider,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";

//Style Material-UI
import { makeStyles } from "@material-ui/core/styles";

//Iconos de Material UI
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";

//Componentes
import Version from "./docsVersion";
import ListUser from "./ListUser/getListUser";
import ListAdmin from "./ListUser/getListAdmin";

//Imagenes
import Logo from "../../static/images/logoChoucair.jpg";

const menuStyles = makeStyles((theme) => ({
    bntCloseMenu: {
        minHeight: "45px",
        padding: 0,
        marginRight: 20,
        [theme.breakpoints.down("sm")]: {
            minHeight: "55px",
        },
    },
    drawerPaper: {
        backgroundColor: "black",
        color: "white",
    },
    box: {
        height: "inherit",
        width: "inherit",
    },
}));

const MenuDrawer = ({ open, toggleDrawer, cambiarInfo, selectedList }) => {
    //========================================================================================================================
    //========================================= Context  =====================================================================
    //========================================================================================================================
    const authContext = useContext(ContextCreate);

    const { strData } = authContext;
    //===============================================================================================================================================
    //========================================== Declaracion de estados =============================================================================
    //===============================================================================================================================================

    const [isAdmin, setIsAdmin] = useState(false);
    //===============================================================================================================================================
    //========================================== Hooks personalizados ===============================================================================
    //===============================================================================================================================================
    const theme = useTheme();
    const movil = useMediaQuery(theme.breakpoints.down("sm"));

    //===============================================================================================================================================
    //============================================= UseEffect  ======================================================================================
    //===============================================================================================================================================
    useEffect(() => {
        let isAdmin = false;
        if (strData.strRolApp) {
            for (let i = 0; i < strData.strRolApp.length; i++) {
                if (strData.strRolApp[i].strNombre === "ADMINISTRADOR") {
                    isAdmin = true;
                }
            }
        }

        if (isAdmin) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [strData]);
    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    const classes = menuStyles();
    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={(e) => toggleDrawer(e, false)}
            classes={{ paper: classes.drawerPaper }}
            variant={movil ? "temporary" : "persistent"}
        >
            <Box display="flex" justifyContent="flex-end">
                <IconButton
                    onClick={(e) => toggleDrawer(e, false)}
                    className={classes.bntCloseMenu}
                >
                    <ChevronLeftIcon style={{ color: "#FFFFFF" }} />
                </IconButton>
            </Box>
            <Box style={{ bottom: 10 }}>
                <Divider style={{ backgroundColor: "#FFFFFF" }} />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                className={classes.box}
                alignItems="center"
            >
                <Box flexGrow={1}>
                    <Box display="flex" justifyContent="center">
                        <Link
                            to="/wilson/inicio"
                            onClick={(e) => {
                                if (movil) {
                                    toggleDrawer(e, false);
                                }
                                cambiarInfo("/wilson/inicio", 0);
                            }}
                        >
                            <img
                                src={Logo}
                                alt="Icono choucair"
                                style={{
                                    width: "150px",
                                    height: "45px",
                                    marginTop: "20px",
                                }}
                            />
                        </Link>
                    </Box>
                    <ListUser
                        index={selectedList}
                        toggleDrawer={toggleDrawer}
                        movil={movil}
                        cambiarInfo={cambiarInfo}
                    />
                    {isAdmin ? (
                        <ListAdmin
                            index={selectedList}
                            toggleDrawer={toggleDrawer}
                            movil={movil}
                            cambiarInfo={cambiarInfo}
                        />
                    ) : null}
                </Box>
                <Box>
                    <Version />
                </Box>
            </Box>
        </Drawer>
    );
};

export default memo(MenuDrawer);
