import React, { useState, useContext, useRef, useCallback, useEffect } from "react";

//Context
import { ContextCreate } from "../../Autenticacion/Context";

//Librerias
import { Redirect, Route } from "react-router-dom";
import axios from "axios";

//Componentes de Material UI
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const RoutePrivate = ({ children, ...props }) => {
    //===============================================================================================================================================
    //========================================== Context ============================================================================================
    //===============================================================================================================================================
    const { token, handlerChangeData, cerrarSesion } = useContext(ContextCreate);

    //===============================================================================================================================================
    //========================================== States =============================================================================================
    //===============================================================================================================================================
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        flag: false,
        msg: undefined,
    });
    //===============================================================================================================================================
    //========================================== Referents ==========================================================================================
    //===============================================================================================================================================
    const handlerChangeDataRef = useRef(handlerChangeData);
    const cerrarSesionRef = useRef(cerrarSesion);
    //===============================================================================================================================================
    //========================================== Function ============================================================================================
    //===============================================================================================================================================
    const getDataToken = useCallback(async (signalGetDataToken, token) => {
        setLoading(true);
        await axios({
            method: "GET",
            baseURL: `${process.env.REACT_APP_PROTOCOL_BACKEND_DATALAKESERVICES}://${process.env.REACT_APP_HOST_BACKEND_DATALAKESERVICES}${process.env.REACT_APP_PORT_BACKEND_DATALAKESERVICES}`,
            url: `${process.env.REACT_APP_API_AUTHORIZE_DATALAKESERVICES}`,
            headers: {
                token,
                strApp:"WILSON"
            },
            cancelToken: signalGetDataToken.token,
        })
            .then((res) => {
                if (res.data.error) {
                    throw new Error(res.data.msg);
                }
                handlerChangeDataRef.current("strData", res.data.data);
                setLoading(false);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    if (error.response) {
                        if (error.response.status === 401) {
                            handlerChangeDataRef.current("token", undefined);
                            return;
                        }
                    }
                    cerrarSesionRef.current();
                    console.error(error);
                    setError({
                        flag: true,
                        msg: error.message,
                    });
                    setLoading(false);
                }
            });
    }, []);

    //===============================================================================================================================================
    //========================================== UseEffects =========================================================================================
    //===============================================================================================================================================
    useEffect(() => {
        let signalGetDataToken = axios.CancelToken.source();
        if (token) {
            getDataToken(signalGetDataToken, token);
        }
        return () => {
            signalGetDataToken.cancel("Petición abortada.");
        };
    }, [token, getDataToken]);

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    if (!token) {
        return <Redirect to="/" />;
    }

    if (loading) {
        return (
            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>
        );
    }

    if (error.flag) {
        return (
            <Alert severity="error">
                <AlertTitle>{error.msg}</AlertTitle>
                Ha ocurrido un error Ruta privada
            </Alert>
        );
    }

    return <Route {...props}>{children}</Route>;
};

export default RoutePrivate;
