import React from "react";

//Librerias
import { Link as RouterLink } from "react-router-dom";

//Componentes de Material UI
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

//Iconos de Material UI
import {
    Extension as ExtensionIcon,
} from "@material-ui/icons";

//Estilos de Material UI
import { makeStyles } from "@material-ui/core/styles";

const listMenuUserStyles = makeStyles({
    list: {
        width: "15rem",
    },
    root: {
        "&$selected": {
            backgroundColor: "#606060",
        },
    },
    selected: {},
});

const GetListUser = ({ toggleDrawer, movil, index, cambiarInfo }) => {
    const classes = listMenuUserStyles();
    return (
        <List component="nav">
            <ListItem
                button
                component={RouterLink}
                to="/wilson/servicios"
                selected={index === 1}
                classes={{ selected: classes.selected, root: classes.root }}
                className={classes.list}
                onClick={(e) => {
                    if (movil) {
                        toggleDrawer(e, false);
                    }

                    cambiarInfo("/wilson/servicios", 1);
                }}
            >
                <ListItemIcon>
                    <ExtensionIcon style={{ color: "white" }} fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Servicios" />
            </ListItem>
        </List>
    );
};

export default GetListUser;
