import React, { Fragment, useEffect } from "react";

import { Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const chatBotStyle = makeStyles(() => ({
    box: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 1200,
    },
}));

const ChatBot = () => {
    const classes = chatBotStyle();

    useEffect(() => {
        customElements
            .whenDefined("df-messenger-chat")
            .then(() => {
                let dfMessengerStyle = document
                    .querySelector("df-messenger")
                    .shadowRoot.querySelector("df-messenger-chat");
                //delete the height css rule
                dfMessengerStyle.shadowRoot.styleSheets[0].deleteRule(3);
                dfMessengerStyle.shadowRoot.styleSheets[0].insertRule(
                    'div.chat-wrapper[opened="true"] { height: 490px; opacity: 1; transform: translate3d(0px, 0px, 0px) scale(1); transition: transform 0.2s ease 0s, opacity 0.2s ease-in 0s; }'
                );
            })
            .catch(() => {
                setTimeout(() => {
                    // let dfMessengerStyle = document
                    //     .querySelector("df-messenger")
                    //     .shadowRoot.querySelector("df-messenger-chat");
                    // //remove the height css rule
                    // dfMessengerStyle.shadowRoot.styleSheets[0].deleteRule(3);
                    // dfMessengerStyle.shadowRoot.styleSheets[0].insertRule(
                    //     'div.chat-wrapper[opened="true"] { height: 490px; opacity: 1; transform: translate3d(0px, 0px, 0px) scale(1); transition: transform 0.2s ease 0s, opacity 0.2s ease-in 0s; }'
                    // );
                }, 5000);
            });
        // eslint-disable-next-line
    }, [1]);

    const scriptChatBot = `
    <style>  
    df-messenger {
        --df-messenger-font-color: #FFFFFF;
        --df-messenger-bot-message: #373e3f;
        --df-messenger-button-titlebar-color: #FFFFFF;
        --df-messenger-button-titlebar-font-color: #000000;
        --df-messenger-chat-background-color: #fafafa;
        --df-messenger-send-icon: #373e3f;
        --df-messenger-user-message: #2fa322;
      }
    </style>
    <div>
      <!--insertar el chatbot-->
      <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
      <df-messenger 
        intent="WELCOME" 
        chat-title="Choquerito"
        chat-icon="/icon-chatbot.png"
        agent-id="ed0d3057-e5a9-4fb4-8668-4a28c8eb1329"
        language-code="es">
        </df-messenger>
        
  </div>
  `;
    return (
        <Fragment>
            <Box className={classes.box}>
                <div id="chatBot" dangerouslySetInnerHTML={{ __html: scriptChatBot }} />
            </Box>
        </Fragment>
    );
};

export default ChatBot;
