import React, { Fragment } from "react";

//Librerias
import { Link as RouterLink } from "react-router-dom";

//Componentes de Material UI
import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";

//Iconos de Material UI
import { AccountBox as AdminIcon } from "@material-ui/icons";

//Estilos de Material UI
import { makeStyles } from "@material-ui/core/styles";

const listMenuUserStyles = makeStyles({
    list: {
        width: "15rem",
    },
    root: {
        "&$selected": {
            backgroundColor: "#606060",
        },
    },
    selected: {},
});

const GetListAdmin = ({ toggleDrawer, movil, index, cambiarInfo }) => {
    const classes = listMenuUserStyles();
    return (
        <Fragment>
            <Divider style={{ backgroundColor: "#FFFFFF" }} />
            <List component="nav">
                <ListItem
                    button
                    component={RouterLink}
                    to="/wilson/admin/users"
                    selected={index === 3}
                    classes={{ selected: classes.selected, root: classes.root }}
                    className={classes.list}
                    onClick={(e) => {
                        if (movil) {
                            toggleDrawer(e, false);
                        }

                        cambiarInfo("/wilson/admin/users", 3);
                    }}
                >
                    <ListItemIcon>
                        <AdminIcon style={{ color: "white" }} fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary="Gestionar información colaborador" />
                </ListItem>
            </List>
        </Fragment>
    );
};

export default GetListAdmin;
