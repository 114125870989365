import React, { lazy, Suspense } from "react";

//Librerias
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Middlewares
import TimeOut from "./middleware/TimeOut";

//Componentes
import Loader from "./components/Loader";
import Menu from "./containers/Menu";
import RutaPrivada from "./components/Rutas/RoutePrivate";
import AuthState from "./Autenticacion/Context";

const Inicio = lazy(() => import("./components/Wilson/Inicio/"));
const Login = lazy(() => import("./containers/Login"));
const Perfil = lazy(() => import("./components/Wilson/Perfil/Navegacion"));
const Servicios = lazy(() => import("./components/Wilson/Servicios"));
const AdminUsers = lazy(() =>
  import("./components/Wilson/Administracion/index")
);
const Verificacion = lazy(() =>
  import("./components/Wilson/carnetDigital/verificacion")
);

const Routes = () => {
  return (
    <>
      <AuthState>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/" component={Login} exact />
              <RutaPrivada path="/wilson">
                <TimeOut>
                  <Menu>
                    <Switch>
                      <Route path="/wilson/inicio" exact component={Inicio} />
                      <Route path="/wilson/perfil" exact component={Perfil} />
                      <Route
                        path="/wilson/servicios"
                        exact
                        component={Servicios}
                      />

                      <Route
                        path="/wilson/admin/users"
                        exact
                        component={AdminUsers}
                      />
                      <Route
                        path="/wilson/perfil/edit/:strEmail/:strnombreCompleto"
                        exact
                        component={Perfil}
                      />
                    </Switch>
                  </Menu>
                </TimeOut>
              </RutaPrivada>
            </Switch>
          </Suspense>
        </Router>
      </AuthState>
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path="/carnetdigital/verificacion/:strEmail"
              exact
              component={Verificacion}
            />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Routes;
