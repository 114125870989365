import React from 'react';
import ReactDOM from 'react-dom';
import Routes from "./Router";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { CssBaseline } from "@material-ui/core"
import { SnackbarProvider } from 'notistack';
//import { ShortenUrlProvider } from "react-shorten-url"

ReactDOM.render(
  <>
    <CssBaseline />
    {/*<ShortenUrlProvider config={{accessToken: process.env.REACT_APP_API_BIT}}>*/}
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    {/*</ShortenUrlProvider>*/}

  </>
  ,
  document.getElementById('root')
);
