import React, { useState } from "react";

//Componentes de material-UI
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
    IconButton,
} from "@material-ui/core";

const DocsVersion = () => {
    const [openDocsVersion, setOpenDocsVersion] = useState(false);
    const handleOpen = () => {
        setOpenDocsVersion(true);
    };
    const handleClose = () => {
        setOpenDocsVersion(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Typography variant="caption" style={{ color: "#FFFFFF" }}>
                    version 1.6.1
                </Typography>
            </IconButton>

            <Dialog
                open={openDocsVersion}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Versionamiento del aplicativo"}
                </DialogTitle>
                <DialogContent>
                <Typography>Versión 1.6.1</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Adición del servicio de persona inactiva en el módulo de Administración" />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Encuesta de Retiro: eliminación del campo *A que se dedica la empresa*" />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Encuesta de Retiro: reorganización del layout de la Encuesta de Retiro" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.6.0</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Adición de la opción Encuesta de Retiro en el módulo de Administración" />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información Familiar: actualización de la validación asociada al campo correo electrónico en contacto en caso de emergencia" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.5.0</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información Básica: adición de los campos Nivel Educativo y Estado del Nivel Educativo" />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información Adicional: adición del campo Raza" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.4.0</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Adición de la visualización de los Reconocimientos para cada colaborador con la opción de direccionarlos a la sección Sala de Trofeos, del aplicativo de Reconocimientos, para consultar el detalle de estos" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.3.0</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Actualización del menú general" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.2.2</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Pantalla Login: eliminación del dialog de inactividad" />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Módulo Información Personal: eliminación del posback cada vez que se ingresa en la sesión activa" />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.2.1</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Correción de errores: PopUp de dirección actual de residencia, formato de números en los teléfonos fijos y móvil, corrección de variables de entorno." />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.2.0</Typography>
                    <List>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información Básica: Adición de campos para ingresar los indicativos de país y región asociados a los números de teléfono fijo y/o móvil." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información Familiar: Adición de campos para ingresar los indicativos de país y región asociados a los números de teléfono fijo y/o móvil." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información Básica: Precarga del los campos del popup de dirección con base en la información ingresada en el campo Dirección actual de residencia. Aplica sólo para cuando en el campo País actual de residencia se seleccione Colombia." />
                        </ListItem>
                    </List>
                    <Typography>Versión 1.1.0</Typography>
                    <List component="nav">
                        <ListItem>
                            <ListItemText secondary="Pantalla Login: Actualización mensaje campo usuario indicando cómo debe ser su correcto diligenciamiento." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Login: Adición de validación en el campo usuario." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Login: Adición de mensaje para el empleo de navegadores de última generación." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla popup Dirección Residencia (Información básica): Corrección navegación con tabulador." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla popup Dirección Residencia (información básica): Adición ejemplo descriptivo para el ingreso de la información." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Perfil: Cambio en el orden de los iconos de las pantallas de Información corporativa y de la de Estar bien." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Home: Cambio de la imagen con indicaciones para un mejor diligenciamiento de los datos." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información básica: Adición mensaje para advertir al colaborador que hacer si encuentra inconsistencias con la información precargada." />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary="Pantalla Información corporativa: Adición mensaje para advertir al colaborador que hacer si encuentra inconsistencias con la información precargada." />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocsVersion;
