import React, { useState, useContext, useRef } from "react";

import { Redirect } from "react-router-dom";

//Context
import { ContextCreate } from "../Autenticacion/Context";

//Librerias
import IdleTimer from "react-idle-timer";

//Componentes de Material UI
import {
    Dialog,
    DialogActions,
    Typography,
    DialogTitle,
    DialogContent,
    Button,
    DialogContentText,
} from "@material-ui/core";

const IdleTimerContainer = ({ children }) => {
    //===============================================================================================================================================
    //========================================== Context ============================================================================================
    //===============================================================================================================================================
    const { cerrarSesion } = useContext(ContextCreate);

    //===============================================================================================================================================
    //========================================== Declaración de estados =============================================================================
    //===============================================================================================================================================
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    //===============================================================================================================================================
    //========================================== Declaración de referencias =========================================================================
    //===============================================================================================================================================
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);

    //===============================================================================================================================================
    //========================================== Funciones ==========================================================================================
    //===============================================================================================================================================
    const onIdle = () => {
        setModalIsOpen(true);
        sessionTimeoutRef.current = setTimeout(logOut, 1000 * 60);
    };

    const logOut = () => {
        setModalIsOpen(false);
        setIsLoggedIn(false);
        clearTimeout(sessionTimeoutRef.current);

        cerrarSesion();
    };

    const stayActive = () => {
        setModalIsOpen(false);
        clearTimeout(sessionTimeoutRef.current);
    };

    const redirectLogin = () => {
        return <Redirect to="/" />;
    };

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    return (
        <>
            {isLoggedIn ? children : redirectLogin()}
            <IdleTimer ref={idleTimerRef} timeout={1000 * 60 * 30} onIdle={onIdle} />

            <Dialog open={modalIsOpen}>
                <DialogTitle>Aviso de inactividad</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Has estado inactivo durante 30 minutos, ¿deseas permanecer en
                        sesión?
                    </DialogContentText>
                    <Typography variant="caption">
                        <b>NOTA:</b>La sesión se cerrara automáticamente en 1 minuto.
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => logOut()}>Salir</Button>

                    <Button color="primary" onClick={() => stayActive()}>
                        Permanecer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default IdleTimerContainer;
