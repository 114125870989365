import React, { useState, createContext, memo } from "react";

import Cookies from "js-cookie";

export const ContextCreate = createContext();

const ContextAPP = ({ children }) => {
    const [data, setData] = useState({
        token: Cookies.get("token") || localStorage.getItem("token") || undefined,
        strData: "",
        informacionCambiada: false,
    });

    const iniciarSesion = (token) => {
        localStorage.setItem("token", token);
        setData((prevState) => ({
            ...prevState,
            token,
        }));
    };

    const handlerChangeData = (type, value) => {
        setData((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    const cerrarSesion = () => {
        setData({
            token: undefined,
            strData: "",
        });
        localStorage.removeItem("token");
        Cookies.remove("token", {
            domain: ".choucairtesting.com",
        });
        Cookies.remove("token");
    };

    const cambiosEnLaInformacion = () => {
        setData((prevState) => ({
            ...prevState,
            informacionCambiada: true,
        }));
    };

    const guardaCambiosDeLaInformacion = () => {
        setData((prevState) => ({
            ...prevState,
            informacionCambiada: false,
        }));
    };

    return (
        <ContextCreate.Provider
            value={{
                iniciarSesion,
                cambiosEnLaInformacion,
                guardaCambiosDeLaInformacion,
                cerrarSesion,
                handlerChangeData,
                token: data.token,
                strData: data.strData,
                informacionCambiada: data.informacionCambiada,
            }}
        >
            {children}
        </ContextCreate.Provider>
    );
};

export default memo(ContextAPP);
